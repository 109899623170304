html,
body {
	/* Reset padding and layout settings */
	width: 100%;
	padding: 0;
	margin: 0;
	font-family: "Montserrat", "Helvetica Neue", Helvetica, "Segoe UI",
		sans-serif;
}

* {
	font-family: "Montserrat", "Helvetica Neue", Helvetica, "Segoe UI",
		sans-serif;
}

.overlay-root {
	z-index: 1;
}
